import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  HStack,
  Link,
  Stack,
  Text,
  useRadio,
  useRadioGroup,
  UseRadioProps,
} from "@chakra-ui/react";
import React, { PropsWithChildren, useContext, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Helmet } from "react-helmet";
import { HomeownerHireContext } from "../../../context/homeowners/homeowner-hire.context";
import { logEvent } from "../../../helpers/analytics";
import { nonEmpty } from "../../../helpers/utils/funtions";
import { useCategories } from "../../../hooks/useCategories";
import { usePageView } from "../../../hooks/usePageView";
import Loading from "../../../_components/Loading";
import RequestFailed from "../../../_components/RequestFailed";
import {
  HireLayout,
  HireLayoutContent,
  HireLayoutFooter,
  HireLayoutHeader,
} from "./layout";
import { ThemedFormLabel } from "./themed-form-label";
import { ThemedInput } from "./themed-input";
import { useApplicationConfig } from "context/ApplicationConfig";
import { useHistory } from "react-router";
import { Category } from "pages/dashboard/types";
import { set } from "date-fns";
import { useAuth0 } from "@auth0/auth0-react";
import { isValidEmail } from "lib/utils";

const CategoryRadioPill = (props: PropsWithChildren<UseRadioProps>) => {
  const { getInputProps, getRadioProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getRadioProps();

  return (
    <Box as="label">
      <input {...input} />
      <Box
        {...checkbox}
        cursor="pointer"
        borderWidth="1px"
        borderColor="gray.700"
        color="gray.900"
        _checked={{
          bg: "flatteredFlamingo.600",
          color: "white",
          borderColor: "flatteredFlamingo.600",
        }}
        _focus={{
          boxShadow: "outline",
        }}
        px={5}
        py={2}
      >
        <Text
          fontSize={{
            base: 13,
            lg: 16,
          }}
        >
          {props.children}
        </Text>
      </Box>
    </Box>
  );
};

export const SelectCategory = () => {
  usePageView();

  const { categories, loading, error } = useCategories();

  const {
    user,
    isLoading: authIsLoading,
    isAuthenticated,
    loginWithRedirect,
    logout,
    getAccessTokenSilently,
  } = useAuth0();

  const { toggles, loading: togglesLoading } = useApplicationConfig();

  const { formData, setFormData, isLoading } = useContext(HomeownerHireContext);

  const [email, setEmail] = React.useState(formData.email || "");

  const [selectedCategory, setSelectedCategory] = React.useState<string | null>(
    formData.category
  );

  const [otherCategoryText, setOtherCategoryText] = React.useState<string>(
    formData.otherCategoryText || ""
  );

  const [showError, setShowError] = React.useState<boolean>(false);

  const [checkingForToken, setCheckingForToken] = React.useState(true);

  useEffect(() => {
    getAccessTokenSilently()
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setCheckingForToken(false);
      });
  }, []);

  useEffect(() => {
    if (!checkingForToken && user?.email && selectedCategory) {
      setFormData(
        {
          ...formData,
          email: user?.email,
          category: selectedCategory,
        },
        "subscription-details"
      );
    }
  }, [user?.email, checkingForToken]);

  const handleCustomCategoryChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setOtherCategoryText(e.target.value);

    setShowError(false);
  };
  const handleChange = (value: string) => {
    logEvent("homeowner/hire/category_option_clicked", {
      categoryName: categories?.find((category) => category.id === value)?.name,
    });
    setSelectedCategory(value);
    setOtherCategoryText("");
    setShowError(false);
  };

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: "artisan",
    value: selectedCategory ?? undefined,
    onChange: handleChange,
  });

  const group = getRootProps();

  let options;

  let otherCategory: Category | undefined;

  let isOtherCategory: boolean = false;

  const leadSource = localStorage.getItem("homeowner_lead_source");

  if (leadSource === "subscriptions_landing_page") {
    options =
      categories?.slice().filter((category: Category) => {
        const subscriptionPackages = category.packages.filter(
          (subcriptionPackage) => subcriptionPackage.isCustom !== true
        );
        return subscriptionPackages.length > 0;
      }) ?? [];
  } else {
    options =
      categories?.slice().filter((category) => category.name !== "Other") ?? [];
    const otherCategory = categories?.find(
      (category) => category.name === "Other"
    );

    isOtherCategory = selectedCategory === otherCategory?.id;
  }

  if (loading || togglesLoading || authIsLoading || checkingForToken)
    return <Loading />;

  if (error) return <RequestFailed />;

  const objectOfSelectedCategory = categories?.find(
    (category) => category.id === selectedCategory
  );

  const subscriptionPackages = objectOfSelectedCategory?.packages.filter(
    (subcriptionPackage) => subcriptionPackage.isCustom !== true
  );

  const categoryHasNonCustomSubscriptionPackages = subscriptionPackages?.length
    ? subscriptionPackages.length > 0
    : false;

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    setShowError(false);
  };

  const handleProceed = () => {
    if (!selectedCategory && !isOtherCategory) {
      setShowError(true);
      return;
    }

    if (!isValidEmail(email) && !isAuthenticated) {
      setShowError(true);
      return;
    }

    if (!formData.email) {
      logEvent("homeowner/hire/email_provided_at_the_start", {
        email,
        category: categories?.find(
          (category) => category.id === selectedCategory
        )?.name,
        otherCategoryText: isOtherCategory ? otherCategoryText : undefined,
      });
    }

    if (isOtherCategory && !nonEmpty(otherCategoryText)) {
      setShowError(true);
      return;
    }

    setShowError(false);

    logEvent("homeowner/hire/category_selected", {
      categoryName: categories?.find(
        (category) => category.id === selectedCategory
      )?.name,
      otherCategoryText: isOtherCategory ? otherCategoryText : undefined,
    });

    // if the category has a subcription package with isCustom not set to true, go to is-subscription-request else go to describe-task
    let nextStep = isOtherCategory
      ? "describe-task"
      : toggles &&
        toggles.homeowner_subscription_requests &&
        categoryHasNonCustomSubscriptionPackages
      ? "is-subscription-request"
      : "describe-task";

    // if isSubscriptionRequest was set after user experienced
    // the flow starting from subscriptions landing page, go to subscription - details if true,
    // else go to describe - task
    if (
      formData.isSubscriptionRequest === true &&
      (leadSource === "subscriptions_landing_page" ||
        leadSource === "cleaning_subscriptions_landing_page" ||
        leadSource === "airconditioning_subscriptions_landing_page" ||
        leadSource === "fumigation_subscriptions_landing_page")
    ) {
      nextStep = "subscription-details";
    }

    if (formData.isSubscriptionRequest === false) {
      nextStep = "describe-task";
    }

    setFormData(
      {
        ...formData,
        email: isAuthenticated ? user?.email : email,
        category: selectedCategory,
        otherCategoryText: isOtherCategory ? otherCategoryText : null,
      },
      nextStep
    );
  };

  const handleCantFindWhatYouWant = () => {
    logEvent("homeowner/hire/cant_find_what_you_want_clicked");
    setFormData({ ...formData }, "cant-find-what-you-want");
  };

  return (
    <>
      <Helmet>
        <title>
          Choose Category | LaborHack Homeowners - Hire certified artisans for
          your home needs
        </title>
      </Helmet>
      <HireLayout>
        <HireLayoutHeader isLoading={isLoading}>
          {leadSource === "subscriptions_landing_page"
            ? "Subscription Request"
            : "What type of artisan do you want to hire?"}
        </HireLayoutHeader>
        <HireLayoutContent>
          <Stack gap={8}>
            <FormControl
              as="fieldset"
              isInvalid={!selectedCategory && showError}
            >
              <ThemedFormLabel as="legend">
                {leadSource === "subscriptions_landing_page"
                  ? "What type of service do you need?"
                  : "Select an artisan that you want to hire"}
              </ThemedFormLabel>
              <Flex {...group} wrap="wrap" gap={4}>
                {options.map((value) => {
                  const radio = getRadioProps({ value: value.id });
                  return (
                    <CategoryRadioPill key={value.id} {...radio}>
                      {leadSource === "subscriptions_landing_page"
                        ? value.name
                        : value.proTitle}
                    </CategoryRadioPill>
                  );
                })}
                {otherCategory && (
                  <CategoryRadioPill
                    {...getRadioProps({ value: otherCategory.id })}
                  >
                    Others
                  </CategoryRadioPill>
                )}
              </Flex>
              <FormErrorMessage>
                {leadSource === "subscriptions_landing_page"
                  ? "Please select the type of service you need"
                  : "Please select the type of artisan you want to hire"}
              </FormErrorMessage>
            </FormControl>
            {isOtherCategory && (
              <FormControl
                isInvalid={!nonEmpty(otherCategoryText) && showError}
              >
                <ThemedFormLabel>Artisan Type</ThemedFormLabel>
                <ThemedInput
                  value={otherCategoryText}
                  onChange={handleCustomCategoryChange}
                  placeholder="Enter the type of artisan"
                />

                <FormErrorMessage>
                  Please enter the type of artisan
                </FormErrorMessage>
              </FormControl>
            )}
            {!isAuthenticated && (
              <FormControl isInvalid={!isValidEmail(email) && showError}>
                <ThemedFormLabel>Email Address</ThemedFormLabel>
                <ThemedInput
                  type="email"
                  value={
                    isAuthenticated && !formData.firstName ? user?.email : email
                  }
                  isDisabled={isAuthenticated && !formData.firstName}
                  onChange={handleEmailChange}
                  placeholder="Enter your email address"
                />

                <FormErrorMessage>Please enter a valid email</FormErrorMessage>
              </FormControl>
            )}
          </Stack>
          {leadSource === "subscriptions_landing_page" && (
            <div>
              <Divider my={8} />
              <Text
                textDecoration={"underline"}
                cursor={"pointer"}
                onClick={handleCantFindWhatYouWant}
              >
                {"Can't find what you're looking for?"}
              </Text>
            </div>
          )}
        </HireLayoutContent>
        <HireLayoutFooter onClick={handleProceed}>Proceed</HireLayoutFooter>
      </HireLayout>
    </>
  );
};
