import {
  Button,
  Center,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Icon,
  InputGroup,
  InputLeftAddon,
  Modal,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
  Textarea,
  VStack,
} from "@chakra-ui/react";
import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import { useRouteMatch } from "react-router-dom";
import { HomeownerHireContext } from "../../../context/homeowners/homeowner-hire.context";
import { logEvent } from "../../../helpers/analytics";
import { usePageView } from "../../../hooks/usePageView";
import {
  HireLayout,
  HireLayoutContent,
  HireLayoutFooter,
  HireLayoutHeader,
} from "./layout";
import { ThemedFormLabel } from "./themed-form-label";
import { ThemedInput } from "./themed-input";
import { Select, SingleValue } from "chakra-react-select";
import { nonEmpty } from "helpers/utils/funtions";
import { gql, useMutation } from "@apollo/client";
import { isValidEmail } from "../../../lib/utils";
import { FaCheck } from "react-icons/fa";
import { Link as RouterLink } from "react-router-dom";
import RequestFailed from "_components/RequestFailed";
import Loading from "_components/Loading";

const CREATE_CUSTOM_SUBSCRIPTION_REQUEST = gql`
  mutation CreateCustomSubscriptionRequest(
    $name: String!
    $email: String!
    $phoneNumber: String!
    $accountType: CustomSubscriptionRequestAccountType!
    $duration: String!
    $specificRequirements: String!
    $categoryId: String!
  ) {
    createCustomSubscriptionRequest(
      name: $name
      email: $email
      phoneNumber: $phoneNumber
      accountType: $accountType
      duration: $duration
      specificRequirements: $specificRequirements
      categoryId: $categoryId
    )
  }
`;

const cleanPhoneNumber = (phoneNumberString: string) => {
  let cleaned = phoneNumberString.trim();
  // remove braces, dashes and spaces
  cleaned = cleaned.replace(/-|\s|\(|\)/g, "");

  if (cleaned.startsWith("0")) {
    cleaned = cleaned.replace(/^0/, "");
  }

  return cleaned;
};

export const isValidPhoneNumber = (phoneNumberString: string) => {
  const cleaned = cleanPhoneNumber(phoneNumberString);

  // Check if the cleaned number is 10 digits long
  if (cleaned.length === 10) {
    return true;
  }

  // If it's not 10 digits long, it's not a number.
  return false;
};

export const CustomSubscriptionDetails = () => {
  usePageView();

  const match = useRouteMatch();

  const [showSuccess, setShowSuccess] = React.useState(false);

  const [
    createCustomSubscriptionRequest,
    { loading: createCustomSubscriptionRequestLoading, error: createCustomSubscriptionRequestError, reset },
  ] = useMutation(CREATE_CUSTOM_SUBSCRIPTION_REQUEST, {
    onCompleted: () => {
      setShowSuccess(true);
    },
  });

  const { formData, setFormData, isLoading, categories  } = useContext(HomeownerHireContext);

  const categoriesWithSubscriptionPackages =
    categories?.filter((cat) => cat.packages.length > 0) || [];

  const category = categoriesWithSubscriptionPackages.find(
    (category) => category.id === formData.category
  );

  const [firstName, setFirstName] = React.useState(formData.firstName || "");
  const [lastName, setLastName] = React.useState(formData.lastName || "");
  const [phoneNumber, setPhoneNumber] = React.useState(
    formData.phoneNumber || ""
  );
  const [email, setEmail] = React.useState(formData.email || "");
  const [showError, setShowError] = React.useState(false);

  const [selectedCategory, setSelectedCategory] = React.useState<SingleValue<{
    value: string;
    label: string;
  }> | null>(
    formData.category
      ? {
          value: formData.category,
          label:
            categories?.find((cat) => cat.id === formData.category)?.name ?? "",
        }
      : null
  );

  const [specificRequirements, setSpecificRequirements] = React.useState("");
  const [duration, setDuration] = React.useState("");

  const canProceed = isValidPhoneNumber(phoneNumber) && firstName && lastName && isValidEmail(email) && nonEmpty(specificRequirements) && nonEmpty(duration) && selectedCategory !== null;

  const handleProceed = () => {
    if (!canProceed) {
      setShowError(true);
      return;
    }

    logEvent("homeowner/hire/custom_subscription_package_requested");

    createCustomSubscriptionRequest({
      variables: {
        name: `${firstName.trim()} ${lastName.trim()}`,
        email: email.trim(),
        phoneNumber: "+234" + cleanPhoneNumber(phoneNumber),
        accountType: "Homeowner",
        duration: duration,
        specificRequirements: specificRequirements,
        categoryId: category?.id,
      },
    });
  };

  const backToPath = match.url.replace(/\/[^/]+$/, "/subscription-details");

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    setShowError(false);
  };

  const handleSpecificRequiremntsChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setSpecificRequirements(e.target.value);
    setShowError(false);
  };

  const handleDurationChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setDuration(e.target.value);
    setShowError(false);
  };

  if (createCustomSubscriptionRequestError) {
    return <RequestFailed onRetry={reset} text={createCustomSubscriptionRequestError.message} />;
  }

  if(createCustomSubscriptionRequestLoading) {
    return <Loading />
  }

  return (
    <>
      <Helmet>
        <title>
          Custom Subscription Request | LaborHack Homeowners - Hire certified
          artisans for your home needs
        </title>
      </Helmet>
      <Modal
        isOpen={showSuccess}
        onClose={() => {
          setShowSuccess(false);
        }}
        closeOnOverlayClick={false}
        size={{
          base: "md",
          lg: "2xl",
        }}
        isCentered
      >
        <ModalOverlay />
        <ModalContent rounded="none">
          <VStack
            p={{
              base: 8,
              lg: 16,
            }}
            spacing={{
              base: 6,
              lg: 12,
            }}
          >
            <VStack
              spacing={{
                base: 4,
                lg: 8,
              }}
            >
              <Center
                rounded="full"
                bgColor="flatteredFlamingo.500"
                p={{
                  base: 4,
                  lg: 6,
                }}
              >
                <Icon
                  as={FaCheck}
                  w={{
                    base: 8,
                    lg: 12,
                  }}
                  h={{
                    base: 8,
                    lg: 12,
                  }}
                  color="white"
                />
              </Center>
              <Heading
                fontSize={{
                  base: 18,
                  lg: 32,
                }}
              >
                Request Sent!
              </Heading>
              <Text
                textAlign="center"
                fontSize={{
                  base: 15,
                  lg: 24,
                }}
                color="gray.500"
              >
                Thank you for sending your custom subscription requirements. Our
                team will be in touch with you shortly.
              </Text>
            </VStack>
            <Button
              as={RouterLink}
              to="/homeowners"
              w={{
                base: "full",
                lg: "60%",
              }}
              fontFamily="AeonikPro"
              size="lg"
              colorScheme="flatteredFlamingo"
              variant="subtle"
              onClick={() => {
                setShowSuccess(false);
              }}
              paddingX={{
                base: 16,
                lg: 32,
              }}
              paddingY={{
                base: 5,
                lg: 10,
              }}
              fontSize={{
                base: 15,
                lg: 24,
              }}
            >
              Close
            </Button>
          </VStack>
        </ModalContent>
      </Modal>
      {!showSuccess && (
        <HireLayout>
          <HireLayoutHeader backTo={backToPath} isLoading={isLoading}>
            Provide Custom Subscription Details
          </HireLayoutHeader>
          <HireLayoutContent>
            <Stack>
              <FormControl isInvalid={showError && !firstName}>
                <ThemedFormLabel>First Name</ThemedFormLabel>
                <ThemedInput
                  id="firstName"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
                <FormErrorMessage>
                  Please enter your first name
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={showError && !lastName}>
                <ThemedFormLabel>Last Name</ThemedFormLabel>
                <ThemedInput
                  id="lastName"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
                <FormErrorMessage>Please enter your last name</FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!isValidEmail(email) && showError}>
                <ThemedFormLabel>Email Address</ThemedFormLabel>
                <ThemedInput
                  id="email"
                  type="email"
                  value={email}
                  onChange={handleEmailChange}
                  placeholder="Enter your email address"
                />
                <FormErrorMessage>Please enter a valid email</FormErrorMessage>
              </FormControl>

              <FormControl
                isInvalid={showError && !isValidPhoneNumber(phoneNumber)}
              >
                <ThemedFormLabel>Phone Number</ThemedFormLabel>
                <InputGroup size="lg">
                  <InputLeftAddon
                    rounded="none"
                    border="1px"
                    borderColor="gray.300"
                    zIndex={99}
                  >
                    🇳🇬 +234
                  </InputLeftAddon>
                  <ThemedInput
                    id="phoneNumber"
                    placeholder="8123456789"
                    value={phoneNumber}
                    type="tel"
                    onChange={(e) => setPhoneNumber(e.target.value)}
                  />
                </InputGroup>

                <FormErrorMessage>
                  Please enter a valid nigerian phone number
                </FormErrorMessage>
              </FormControl>

              <FormControl>
                <ThemedFormLabel color={"#A9A9A9"}>
                  What type of service do you need?
                </ThemedFormLabel>
                <Select
                  value={selectedCategory}
                  placeholder="Select category"
                  onChange={(value) =>
                    setSelectedCategory(() => {
                      setFormData({
                        ...formData,
                        category: value!.value,
                      });
                      return value;
                    })
                  }
                  options={categoriesWithSubscriptionPackages!.map((cat) => ({
                    value: cat.id,
                    label: cat.name,
                  }))}
                />
              </FormControl>

              <FormControl>
                <ThemedFormLabel>Specific Requirements</ThemedFormLabel>
                <Textarea
                  isInvalid={!nonEmpty(specificRequirements) && showError}
                  borderRadius={0}
                  variant="filled"
                  value={specificRequirements}
                  onChange={handleSpecificRequiremntsChange}
                  size="lg"
                  noOfLines={20}
                  placeholder="e.g include any details you think the tradesperson should know
              (approx. extension dimensions, timeframe, etc.)"
                />

                <FormErrorMessage>
                  Please provide the specific requirements
                </FormErrorMessage>
              </FormControl>

              <FormControl>
                <ThemedFormLabel>Duration of Subscription</ThemedFormLabel>
                <Textarea
                  isInvalid={!nonEmpty(duration) && showError}
                  borderRadius={0}
                  variant="filled"
                  value={duration}
                  onChange={handleDurationChange}
                  size="lg"
                  noOfLines={20}
                  placeholder="e.g weekly, bi-weekly, monthly, etc."
                />

                <FormErrorMessage>
                  Please provide the specific requirements
                </FormErrorMessage>
              </FormControl>
            </Stack>
          </HireLayoutContent>
          <HireLayoutFooter isLoading={isLoading} onClick={handleProceed}>
            Proceed
          </HireLayoutFooter>
        </HireLayout>
      )}
    </>
  );
};
