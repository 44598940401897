import { gql, useLazyQuery, useMutation } from "@apollo/client";
import React, { PropsWithChildren, useEffect, useRef } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { AddressPayload, Client, HomeownerRequest, LeadSource} from "../../types";
import Loading from "../../_components/Loading";
import RequestFailed from "../../_components/RequestFailed";
import { produce } from "immer";
import { HOMEOWNER_HIRE_FORM_DATA_LOCAL_STORE_KEY } from "../../constants";
import { isValidHomeownerHireStartDate } from "../../lib/utils";
import { useApplicationConfig } from "context/ApplicationConfig";
import { useCategories } from "hooks/useCategories";
import { Category } from "pages/dashboard/types";
import { MY_CLIENT } from "../../graphql/query.constants";
import { useAuth0 } from "@auth0/auth0-react";
import { is } from "immer/dist/internal";

const FINALIZE_HOMEOWNER_REQUEST = gql`
  mutation FinalizeHomeownerRequest($id: String!) {
    finalizeHomeownerRequest(id: $id) {
      id
      isComplete
    }
  }
`;

const HOMEOWNER_FIELDS = gql`
  fragment HomeownerFields on HomeownerRequest {
    id
    category {
      id
      name
    }
    client {
      id
      email
      addresses {
        id
        address
        city
        state
      }
    }
    otherCategoryText
    plan {
      id
      interval
      package {
        id
        name
      }
    }
    invoice {
      id
      appliedDiscount {
        id
        code
        value
      }
    }
    description
    email
    address {
      id
      address
      city
      state
      location {
        coordinates
      }
    }
    frequency
    startDate
    firstName
    lastName
    isPaid
    phoneNumber
    isComplete
  }
`;

const GET_HOMEOWNER_REQUEST = gql`
  ${HOMEOWNER_FIELDS}
  query GetHomeownerRequest($id: String!) {
    homeownerRequest(id: $id) {
      ...HomeownerFields
    }
  }
`;

const UPDATE_HOMEOWNER_REQUEST = gql`
  ${HOMEOWNER_FIELDS}
  mutation UpdateHomeownerRequest(
    $id: String!
    $category: String
    $otherCategoryText: String
    $plan: String
    $description: String
    $email: String
    $address: AddressInput
    $addressId: String
    $frequency: HomeownerRequestTaskFrequency
    $startDate: DateTimeISO
    $firstName: String
    $lastName: String
    $phoneNumber: String
    $leadSource: String
    $otherLeadSourceText: String
  ) {
    updateHomeownerRequest(
      id: $id
      category: $category
      otherCategoryText: $otherCategoryText
      description: $description
      plan: $plan
      email: $email
      address: $address
      addressId: $addressId
      frequency: $frequency
      startDate: $startDate
      firstName: $firstName
      lastName: $lastName
      phoneNumber: $phoneNumber
      leadSource: $leadSource
      otherLeadSourceText: $otherLeadSourceText
    ) {
      ...HomeownerFields
    }
  }
`;

interface HomeownerHireFormData {
  id: string | null;
  category: string | null;
  otherCategoryText: string | null;
  description: string | null;
  email: string | null;
  address: AddressPayload | null;
  isSubscriptionRequest: boolean | null;
  subscriptionPackage: string | null;
  plan: string | null;
  subscription: string | null;
  //frequency: HomeownerRequestTaskFrequency | null;
  startDate: Date | null;
  firstName?: string | null;
  lastName?: string | null;
  phoneNumber?: string | null;
  leadSource?: LeadSource | null;
  otherLeadSourceText?: string | null;
  isPaid?: boolean | null;
}

export interface HomeownerHireContextProps {
  formData: HomeownerHireFormData;
  canCreateRequest: boolean;
  setFormData: (
    values: Partial<HomeownerHireFormData> & { addressId?: string },
    to?: string,
    onCompleted?: () => void
  ) => void;
  isLoading?: boolean;
  isNew?: boolean;
  client?: Client;
  transactionReference?: string;
  categories: Category[] | null;
  categoryHasNonCustomSubscriptionPackages: boolean;
  homeownerRequest?: HomeownerRequest;
}

export const defaultFormDataValue = {
  id: null,
  category: null,
  otherCategoryText: null,
  description: null,
  isSubscriptionRequest: null,
  subscriptionPackage: null,
  plan: null,
  subscription: null,
  email: null,
  address: null,
  frequency: null,
  startDate: null,
  isPaid: null,
  leadSource: null,
  otherLeadSourceText: null,
};

export const initialValue: HomeownerHireContextProps = {
  formData: defaultFormDataValue,
  canCreateRequest: false,
  setFormData: () => {},
  categories: [],
  categoryHasNonCustomSubscriptionPackages: false,
};

export const HomeownerHireContext = React.createContext(initialValue);

let categoryHasNonCustomSubscriptionPackages: boolean;

const convertHomeownerRequestToFormData = (
  homeownerRequest: HomeownerRequest
) => {
  const {
    id,
    category,
    otherCategoryText,
    plan,
    subscription,
    description,
    email,
    isPaid,
    address,
    startDate,
    firstName,
    lastName,
    phoneNumber,
    leadSource,
    otherLeadSourceText,
  } = homeownerRequest;

  return {
    id,
    category: category.id,
    otherCategoryText,
    description,
    isSubscriptionRequest: !!plan,
    subscription: subscription && subscription.id,
    isPaid,
    subscriptionPackage: plan && plan?.package.id,
    plan: plan && plan?.id,
    email,
    address: address && {
      id: address.id,
      address: address.address,
      city: address.city,
      state: address.state,
      coordinates: address.location.coordinates,
    },
    startDate: startDate ? new Date(startDate) : null,
    firstName,
    lastName,
    phoneNumber,
    leadSource,
    otherLeadSourceText,
  };
};

export const HomeownerHireProvider = ({ children }: PropsWithChildren<{}>) => {
  const history = useHistory();

  const location = useLocation();

  const { search } = location;

  const query = new URLSearchParams(search);

  const homeownerRequestQueryParameter = query.get("homeowner_request");

  if (homeownerRequestQueryParameter) {
    query.delete("homeowner_request");
  }

  useEffect(() => {
    if (homeownerRequestQueryParameter) {
      history.push(
        `/homeowners/hire/${homeownerRequestQueryParameter}?${query.toString()}`
      );
    }
  }, []);

  const { isLoading, user, isAuthenticated, logout, loginWithRedirect } =
    useAuth0();

  const [getMyClient, getMyClientHandle] =
    useLazyQuery<{
      myClient: Client | null;
    }>(MY_CLIENT);

  useEffect(() => {
    if (isAuthenticated) {
      getMyClient();
    }
  }, [isAuthenticated]);

  const {
    categories,
    loading: getCategoriesIsLoading,
    error: getCategoriesError,
  } = useCategories();

  const { toggles, loading: togglesIsLoading } = useApplicationConfig();

  const getCurrentStep = React.useCallback(
    (
      formData: HomeownerHireFormData,
      isNew = false,
      client: Client | null = null,
      categories: Category[] | null = null
    ) => {
      if (!categories) {
        /**
         * This should never happen, but if it does,
         * we can default to the category selection page as the flow is broken without it
         */
        return "";
      }

      //console.log({formData})

      const isOtherCategory =
        categories.find((category) => category.id === formData.category)
          ?.name === "Other";

      const objectOfSelectedCategory = categories?.find(
        (category) => category.id === formData.category
      );

      const subscriptionPackages = objectOfSelectedCategory?.packages.filter(
        (subcriptionPackage) => subcriptionPackage.isCustom !== true
      );

      categoryHasNonCustomSubscriptionPackages = !!subscriptionPackages?.length;

      if (
        !formData.category ||
        (isOtherCategory && !formData.otherCategoryText)
      ) {
        return "";
      }

      if (isNew && !formData.email) {
        return "select-category"; // we are now collecting email on the first step
      }

      if (
        toggles &&
        toggles.homeowner_subscription_requests &&
        categoryHasNonCustomSubscriptionPackages &&
        formData.isSubscriptionRequest == null
      ) {
        return "is-subscription-request";
      }

      if (
        toggles &&
        toggles.homeowner_subscription_requests &&
        categoryHasNonCustomSubscriptionPackages &&
        formData.isSubscriptionRequest &&
        !formData.plan
      ) {
        return "subscription-details";
      }

      if (toggles && toggles.homeowner_subscription_requests) {
        if (
          !formData.description &&
          ((categoryHasNonCustomSubscriptionPackages &&
            formData.isSubscriptionRequest != null &&
            formData.isSubscriptionRequest !== true) ||
            (!categoryHasNonCustomSubscriptionPackages &&
              formData.isSubscriptionRequest == null))
        ) {
          return "describe-task";
        }
      } else {
        if (!formData.description) {
          return "describe-task";
        }
      }

      if (!formData.address) {
        return "address-selector";
      }

      if (
        toggles &&
        toggles?.handle_subscription_payments &&
        formData.plan &&
        !formData.isPaid
      ) {
        return "subscription-payment";
      }

      const isValidDate =
        formData.startDate && isValidHomeownerHireStartDate(formData.startDate);

      if (!isValidDate) {
        return "start-date";
      }

      if (
        !client &&
        (!formData.firstName ||
          !formData.lastName ||
          !formData.phoneNumber ||
          !formData.leadSource ||
          (formData.leadSource === LeadSource.Other &&
            !formData.otherLeadSourceText))
      ) {
        return "profile";
      }

      return "confirmation";
    },
    [toggles]
  );

  /**
   * where the user comes from before landing on the homeowner hire flow (e.g subscriptions page)
   * different from client lead source (e.g radio, referral, social media)
   */
  const leadSource: string | undefined = query.get("lead_source") ?? undefined;

  // Using useRef to store the transaction reference persistently
  const transactionReferenceRef = useRef<string | undefined>(
    query.get("trxref") ?? undefined
  );

  const transactionReferenceFromPaystack = transactionReferenceRef.current;

  let initialIsSubscriptionRequestValue;

  if (query.get("is_subscription_request") === "true") {
    initialIsSubscriptionRequestValue = true;
  } else if (query.get("is_subscription_request") === "false") {
    initialIsSubscriptionRequestValue = false;
  } else {
    initialIsSubscriptionRequestValue = null;
  }

  leadSource && localStorage.setItem("homeowner_lead_source", leadSource);

  const initialFormDataValue: HomeownerHireFormData = {
    ...defaultFormDataValue,
    category: query.get("category"),
    isSubscriptionRequest: initialIsSubscriptionRequestValue,
  };

  let localStoreFormData: HomeownerHireFormData | null = null;

  const locatStoreFormDataAsString = localStorage.getItem(
    HOMEOWNER_HIRE_FORM_DATA_LOCAL_STORE_KEY
  );

  if (locatStoreFormDataAsString) {
    localStoreFormData = JSON.parse(locatStoreFormDataAsString);
  }

  const { requestId } = useParams<{ requestId: string }>();

  const isNew = requestId === "new";

  const [formData, setFormData] = React.useState<HomeownerHireFormData>(
    localStoreFormData || initialFormDataValue
  );

  useEffect(() => {
    if (isNew && categories) {
      const path = getCurrentStep(formData, true, null, categories);

      history.push(`/homeowners/hire/${requestId}${path ? `/${path}` : ""}`);
    }
  }, [toggles, categories]);

  const [homeownerRequest, homeownerRequestResponse] = useLazyQuery<{
    homeownerRequest: HomeownerRequest;
  }>(GET_HOMEOWNER_REQUEST, {
    onCompleted: ({ homeownerRequest }) => {
      if (homeownerRequest.isComplete) {
        history.push(`/homeowners/hire/${requestId}/confirmation`);
        return;
      }

      const data = convertHomeownerRequestToFormData(homeownerRequest);

      const path = getCurrentStep(
        data,
        false,
        homeownerRequest.client,
        categories
      );

      history.push(`/homeowners/hire/${requestId}${path ? `/${path}` : ""}`);
    },
  });

  useEffect(() => {
    const homeownerClient =
      homeownerRequestResponse.data?.homeownerRequest.client;

    const loggedInClient = getMyClientHandle.data?.myClient;

    if (!homeownerClient || !loggedInClient) {
      return;
    }

    if (loggedInClient.id !== homeownerClient.id) {
      logout({
        returnTo: `${window.location.origin}/homeowners/hire/new?homeowner_request=${requestId}`,
      });
    }
  }, [homeownerRequestResponse.data, getMyClientHandle.data]);

  useEffect(() => {
    if (
      !isAuthenticated &&
      !isLoading &&
      homeownerRequestResponse.data?.homeownerRequest.client
    ) {
      loginWithRedirect({
        redirectUri: `${window.location.origin}/homeowners/hire/new?homeowner_request=${requestId}`,
        login_hint: homeownerRequestResponse.data.homeownerRequest.client.email,
      });
    }
  }, [isLoading, isAuthenticated, homeownerRequestResponse.data]);

  const [
    finalizeHomeownerRequest,
    { loading: isFinalizing, error: finalizeError },
  ] = useMutation(FINALIZE_HOMEOWNER_REQUEST, {
    variables: {
      id: requestId,
    },
    onCompleted: () => {
      history.push(`/homeowners/hire/${requestId}/confirmation`);
    },
  });

  const [updateHomeownerRequest, updateHomeownerRequestResponse] = useMutation<{
    updateHomeownerRequest: HomeownerRequest;
  }>(UPDATE_HOMEOWNER_REQUEST);

  const updateFormData = (
    values: Partial<HomeownerHireFormData> & { addressId?: string },
    to?: string,
    onCompleted?: () => void
  ) => {
    const moveToUrl =
      to !== undefined
        ? `/homeowners/hire/${requestId}${to ? `/${to}` : ""}`
        : undefined;

    if (isNew) {
      setFormData((prev) => {
        const nextState = produce(prev, (draft) => {
          Object.assign(draft, values);
        });
        return nextState;
      });

      if (!moveToUrl) {
        return;
      }

      onCompleted && onCompleted();

      history.push(moveToUrl);
    } else {
      updateHomeownerRequest({
        variables: {
          id: requestId,
          ...values,
        },
        onCompleted: () => {
          if (to === "confirmation") {
            finalizeHomeownerRequest();
            return;
          }

          if (!moveToUrl) {
            return;
          }

          onCompleted && onCompleted();
          history.push(moveToUrl);
        },
      });
    }
  };

  useEffect(() => {
    if (isNew) {
      return;
    }

    if (categories) {
      homeownerRequest({
        variables: {
          id: requestId,
        },
      });
    }
  }, [requestId, categories, toggles]);

  const needForDescription =
    formData.isSubscriptionRequest === undefined ||
    formData.isSubscriptionRequest === false;

  const canCreateRequest =
    !!formData.category && (needForDescription ? !!formData.description : true);

  if (
    isLoading ||
    getMyClientHandle.loading ||
    homeownerRequestResponse.loading ||
    getCategoriesIsLoading ||
    togglesIsLoading
  ) {
    return <Loading />;
  }

  if (homeownerRequestResponse.error || getCategoriesError) {
    return <RequestFailed />;
  }

  if (finalizeError) {
    return (
      <RequestFailed
        text={
          "We encountered an error while finalizing your request. Please try again."
        }
        onRetry={() => finalizeHomeownerRequest()}
      />
    );
  }

  const context = {
    formData: !homeownerRequestResponse.data
      ? formData
      : convertHomeownerRequestToFormData(
          homeownerRequestResponse.data.homeownerRequest
        ),
    canCreateRequest,
    setFormData: updateFormData,
    isLoading:
      updateHomeownerRequestResponse.loading ||
      isFinalizing ||
      getCategoriesIsLoading,
    isNew,
    homeownerRequest: homeownerRequestResponse.data?.homeownerRequest,
    client: homeownerRequestResponse.data?.homeownerRequest.client || undefined,
    transactionReference: transactionReferenceFromPaystack,
    categories: categories || [],
    categoryHasNonCustomSubscriptionPackages,
  };

  return (
    <HomeownerHireContext.Provider value={context}>
      {children}
    </HomeownerHireContext.Provider>
  );
};
