import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import ProProfilePage from "./[handle]";
import ProInvoice from "./[handle]/invoices/[id]";
import Navbar from "../../_components/Navbar/Navbar";

export default function Pros() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/invoices/:id`}>
        <ProInvoice />
      </Route>
      <Route path="">
        <Navbar />
        <ProProfilePage />
      </Route>
    </Switch>
  );
}
