import React from "react";
import { Check } from "lucide-react";
import { Badge, Divider, Heading, Image } from "@chakra-ui/react";
import { gql } from "../../../../../__generated__";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router";
import { format } from "date-fns";
import { formatToNaira } from "../../../../../helpers/utils/funtions";
import {
  ArtisanInvoiceQuery,
  ArtisanInvoiceStatus,
} from "../../../../../__generated__/graphql";
import RequestFailed from "../../../../../_components/RequestFailed";
import { Link } from "react-router-dom";
import WhiteLogo from "../../../../../assets/logos/white.svg";

const getInvoiceBadgeProps = (status: ArtisanInvoiceStatus) => {
  switch (status) {
    case ArtisanInvoiceStatus.Paid:
      return {
        colorScheme: "green",
        text: "Paid",
        icon: Check,
      };
    case ArtisanInvoiceStatus.Pending:
      return {
        colorScheme: "yellow",
        text: "Awaiting Payment",
      };
    case ArtisanInvoiceStatus.Cancelled:
      return {
        colorScheme: "red",
        text: "Cancelled",
      };
  }
};

const GET_ARTISAN_INVOICE = gql(
  `query ArtisanInvoice($invoiceId: String!) {
    artisanInvoice(invoiceId: $invoiceId) {
      createdAt
      status
      invoiceNumber
      labor
      materials {
        cost
        name
      }
      client {
        name
        address
        phoneNumber
      }
      user {
        business {
          address
          name
        }
        firstName
        lastName
        pro {
          associatedLaborHackEmail
          paymentProviderInformation {
          defaultAccountNumber
          id
          providerData {
            accountNumber
            providers {
              bankInformation {
                accountName
                accountNumber
                bankName
              }
            }
          }
        }
        }
      }
      task {
        description
        name
      }
    }
  }`
);

export default function ProInvoice() {
  const { handle, id } =
    useParams<{
      handle: string;
      id: string;
    }>();

  const { data, loading, error } = useQuery(GET_ARTISAN_INVOICE, {
    variables: {
      invoiceId: id,
    },
  });

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const [proHandle, _] =
    data?.artisanInvoice?.user?.pro?.associatedLaborHackEmail.split("@") || [];

  if (!data?.artisanInvoice || proHandle !== handle) {
    return <RequestFailed text="Invoice does not exist" />;
  }

  const total = data.artisanInvoice.materials.reduce(
    (acc, material) => acc + material.cost,
    data.artisanInvoice.labor
  );

  let bankDetails: NonNullable<
    NonNullable<
      NonNullable<ArtisanInvoiceQuery["artisanInvoice"]>["user"]["pro"]
    >["paymentProviderInformation"]
  >["providerData"][number]["providers"][number]["bankInformation"] = null;

  if (data.artisanInvoice.user.pro?.paymentProviderInformation) {
    const { defaultAccountNumber, providerData } =
      data.artisanInvoice.user.pro?.paymentProviderInformation;

    if (defaultAccountNumber) {
      const defaultAccount = providerData.find(
        (account) => account.accountNumber === defaultAccountNumber
      );

      if (defaultAccount) {
        const defaultProvider = defaultAccount.providers.find(
          (provider) => provider.bankInformation
        );

        if (defaultProvider) {
          bankDetails = defaultProvider.bankInformation;
        }
      }
    }
  }

  const { colorScheme, text } = getInvoiceBadgeProps(
    data.artisanInvoice.status
  );

  return (
    <div className="max-w-4xl mx-auto bg-white text-foreground">
      <div className="flex flex-col border-b p-6 w-full">
        <div className="flex justify-between items-center w-full py-2">
          <Heading className="text-3xl font-semibold proportional-nums">
            Invoice #{data.artisanInvoice.invoiceNumber}
          </Heading>
          <Badge
            fontSize={{
              base: 12,
              sm: 14,
            }}
            paddingX={2}
            colorScheme={colorScheme}
          >
            {text}
          </Badge>
        </div>
      </div>

      <div className="grid sm:grid-cols-2 border-b">
        <div className="border-r">
          <div className="p-4 md:p-6 border-b">
            <p className="text-sm text-muted-foreground">Issued On</p>
            <p className="font-medium">
              {format(new Date(data.artisanInvoice.createdAt), "MMMM dd, yyyy")}
            </p>
          </div>
          <div className="p-4 md:p-6">
            <p className="text-sm text-muted-foreground">From</p>
            <div className="font-medium">
              <p>
                {data.artisanInvoice.user.business?.name ||
                  `${data.artisanInvoice.user.firstName} ${data.artisanInvoice.user.lastName}`}
              </p>
              {data.artisanInvoice.user.business && (
                <p>{data.artisanInvoice.user.business?.address}</p>
              )}
              <p>Nigeria</p>
            </div>
          </div>
        </div>
        <div>
          <div className="p-4 md:p-6 border-b">
            <p className="text-sm text-muted-foreground">Due On</p>
            <p className="font-medium">
              {format(new Date(data.artisanInvoice.createdAt), "MMMM dd, yyyy")}
            </p>
          </div>
          <div className="p-4 md:p-6">
            <p className="text-sm text-muted-foreground">To</p>
            <div className="font-medium">
              <p>{data.artisanInvoice.client.name}</p>
              <p>{data.artisanInvoice.client.address}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="border-b p-4 md:p-6">
        <p className="text-sm text-muted-foreground">Task</p>
        <p className="font-medium">{data.artisanInvoice.task.name}</p>
      </div>

      <div className="space-y-4 p-4 md:p-6">
        <Heading className="!text-xl font-semibold">Items</Heading>
        <div className="border">
          <div className="grid grid-cols-2 p-4 border-b text-sm font-medium">
            <div>Description</div>
            <div className="text-right">Amount</div>
          </div>
          {data.artisanInvoice.materials.map((material) => (
            <div className="grid grid-cols-2 px-4 py-2" key={material.name}>
              <div>{material.name}</div>
              <div className="text-right">{formatToNaira(material.cost)}</div>
            </div>
          ))}
          <div className="grid grid-cols-2 px-4 py-2">
            <div>Labor</div>
            <div className="text-right">
              {formatToNaira(data.artisanInvoice.labor)}
            </div>
          </div>
        </div>
        <div className="flex justify-end">
          <div className="space-y-1 px-4">
            <div className="flex justify-between gap-8">
              <p className="text-muted-foreground">Total</p>
              <p className="font-medium">{formatToNaira(total)}</p>
            </div>
          </div>
        </div>
      </div>

      <Divider />

      {bankDetails && (
        <div className="space-y-2 p-4 md:p-6">
          <Heading as="h3" className="!text-xl font-semibold">
            Bank Details
          </Heading>
          <div className="grid sm:grid-cols-2 gap-4">
            <div>
              <p className="text-sm text-muted-foreground">Bank Name</p>
              <p className="font-medium">{bankDetails.bankName}</p>
            </div>
            <div>
              <p className="text-sm text-muted-foreground">Account Name</p>
              <p className="font-medium">{bankDetails.accountName}</p>
            </div>
            <div>
              <p className="text-sm text-muted-foreground">Account Number</p>
              <p className="font-medium">{bankDetails.accountNumber}</p>
            </div>
          </div>
        </div>
      )}

      <div className="space-y-2 p-4 md:p-6 bg-flatteredFlamingo-500">
        <div className="flex flex-col gap-2">
          <p className="font-[AeonikPro] font-semibold text-white">
            Powered By
          </p>
          <Link to="/">
            <Image
              src={WhiteLogo}
              alt="LaborHack"
              height="24px"
              width="174px"
            />
          </Link>
        </div>
      </div>
    </div>
  );
}
